.App{
  height: 100vh;
  width: 100vw;
  background-color: rgb(61, 61, 61);
  // background: url()
}

.routes{
  padding-top: 15vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./images/wallpaper-1957745_1280.jpg');
  // background: url('./wallpaper-1957745_1280.jpg');
  background-color: rgba(0, 0, 0, 0.219);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 5px 5px 5px black;
}
// a:-webkit-any-link{
//   color: white;
//   text-decoration: none;
// }

button, .submitCon{
  cursor: pointer
}
.indent{
  margin: 0 2vw;
}

@media (orientation: landscape){
  .routes{
    padding-top: 25vh
  }
}

@media screen and (min-width: 1024px){
  .App{
    overflow-y: scroll;
    // background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEM4H3SuhKHY5Rp0NjhIQ-s-4MGYNlV9Ys4_v5Q457yhkEVuB5');
    // background: url('https://wallpaper-house.com/data/out/9/wallpaper2you_332195.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
  }
  .routes{
    padding-top: 22.5vh;
  }

  .indent{
    margin: 0 1vw;
  }

}