.categoryP{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 85vh;
    overflow-y: scroll;
    // background-color: lightblue;
    >.categoryC{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h1{
            color: white;
            text-align: center;
        }
        >div:nth-child(2){
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: fit-content;
            >a >.concreteImg{
                min-height: 50vh;
                min-width: 90vw;
                height: 50vh;
                width: 90vw;
                margin: 10px 0;
            }
            >a >img{
                min-height: 50vh;
                min-width: 90vw;
                height: 50vh;
                width: 90vw;
                margin: 10px 0;
            }
            >button{
                background-color: white;
                min-height: 20px;
                margin-bottom: 10px;
            }
           
        }
    }
}

@media (orientation: landscape){
    .categoryP{
        height: 75vh;
        >.categoryC{
            >div:nth-child(2){
                >a >.concreteImg{
                    min-width: 50vw;
                    min-height: 85vh;
                    width: 75vw;
                }
                >a >img{
                    min-height: 75vh;
                    min-width: 50vw;
                    width: 75vw;
                }
                // >button{
                     // margin-bottom: 50px;
                // }
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .categoryP{
        height:  77.5vh;
        >.categoryC{
            display: flex;
            // justify-content: center;
            // align-items: center;
            h1{

            }
            >div:nth-child(2){
                >a >.concreteImg{
                    // min-height: 75vh;
                    min-width: 60vw;
                    // height: 75vh;
                    width: 60vw;
                    margin-bottom: 10px;
                }
                >a >img{
                    // min-height: 75vh;
                    min-width: 60vw;
                    // height: 75vh;
                    width: 60vw;
                    margin-bottom: 10px;
                }
                >button{
                    display: block;
                }
            }
            
        }
    }
    
}