.openingInfoP{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 8vh;
    margin-bottom: 10vh;
    >*{
        margin: 0;
    }
    >hr{
        opacity: 0;
    }
    a{
        text-decoration: none;
        color: white;
    }
    span > em{
        display: none;
    }
}

@media (orientation: landscape) {
    .openingInfoP{
        margin-top: 12vh;
        margin-bottom: 12vh;
    }
}

@media screen and (min-width: 1024px){
    .openingInfoP{
        margin-top: 2vh;
        margin-bottom: 2.5vh;
        >h1{
            padding-bottom: 1vh;
        }
        >hr{
            opacity: 0;
            width: 0px;
            transition: 1s; 
        }
        span > a{
            display: none;
        }
        span > em{
            display: block;
        }
        >span:hover + hr{
            opacity: 1;
            width: 200px;
            border: solid 1px yellowgreen;
        }
    }
}