.navbarp{
    position: fixed;
    height: 15vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbarc{
        height: 100%;
        width: 50%;
        padding-left: 5%;
        > h1{
            color: white;
        }
        > img{
            position: absolute;
            height: 15vh;
            width: 63vw;
            // left: -1%;
            // top: -12.5%;
        }
    }
    .navbarlinks{
        padding-right: 5%;
        > .tabdropdown{
            color: white;
            font-size: 40px;
        }
        > .tabdropdown:active{
            color: greenyellow;
        }
        > .desktoplinks{
            display: none;
        }
    }
}
@media (orientation: landscape){
    .navbarp{
        height: 25vh;
        >.navbarc{
            >img{
                height: 25vh;
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .navbarp{
        height: 22.5vh;
        flex-direction: column;
        >.navbarc{
            padding-left: 0;
            > img{
                position: absolute;
                height: 20vh;
                width: 45vw;
                left: 20%;
                // top: -20%;
            }
        }
        
        >.navbarlinks{
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0;
            >.desktoplinks{
                position: relative;
                top: 10px;
                height: 10vh;
                width: 50vw;
                display: flex;
                justify-content: space-evenly;
                align-items: flex-end;
                margin-bottom: 2.5vh;
                >*{
                    color: white;
                    text-decoration: none;
                    font-size: 30px;
                }
                .navlinks{
                    transition: 0.5s;
                }
                .navlinks:hover{
                    color: greenyellow;
                    transform: scale(1.2);
                    text-shadow: 5px 5px 5px black;
                }
            }
        }
    }
    
    
    .tabdropdown{
        display: none;
    }
}