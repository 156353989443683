.aboutp{
    height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    >.aboutc{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 85%;
        // background-color: grey;
        >div:nth-child(1){
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            color: white;
            height: 90%;
            >h1{
                // border-bottom: 1px solid white;
                margin: 0;
                text-align: center;
            }
            >h2{
                margin: 0;
                text-align: center;
                font-style: italic;
                font-weight: 300;
                // font-family: cursive;
            }
            >p{
                // text-align: center;
                font-size: 14px;
                overflow-y: scroll;
            }
        }

        >div:nth-child(2){
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            height: 12.5%;
            // height: fit-content;
            width: 100vw;
            // background-color: lightblue;
            background-color: rgba(172, 255, 47, 0.678);
            text-shadow: none;
            // border-bottom-right-radius: 10px;
            // border-bottom-left-radius: 10px;
            overflow-y: scroll;
            p{
                margin: 0;
                border-bottom: 1px solid black;
            }
            a{
                cursor: pointer;
                color: black;
                text-decoration: none;
            }
            >div{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
   
}
@media (orientation: landscape) {
    .aboutp{
        height: 75vh;
        >.aboutc{
            >div:nth-child(1){
                >h1{
                    // margin: 10px 0;
                }
                >p{
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
            >div:nth-child(2){
                overflow-y: scroll;
                height: 25%;
                
            }
        }
    }
  }

@media screen and (min-width: 1024px){
    .aboutp{
        height: 77.5vh;
        >.aboutc{
            width: 50%;
            >div:nth-child(1){
                margin-top: 20px;
                // font-size: 20px;
                >h1{
                    font-size: 40px;
                }
                >h2{
                    margin-bottom: 20px;
                }
                >p{
                    font-size: 20px;
                }
            }
            >div:nth-child(2){
                height: 10%;
                max-width: 40vw;
            }
        }
    }
}
