.contactp{
    height: 85vh;
    width: 100vw;
    // background-color: rgb(61, 61, 61);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >.contactc{
        height: 90%;
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        >*{
            text-align: center;
            margin: 10px 0;
        }
        >h1{
            color: white;
            font-size: 20px;
        }
        >.contactpar{
            text-align: left;
            font-size: 16px;
            color: white;
        }
        >.submitCon{
            background-color: greenyellow;
            border: none;
            border-radius: 5px 5px;
            color: black;
        }
        >input{
            border-radius: 5px 5px;
            background: rgba(61,61,61,0.5);
            color: white;
            font-size: 20px;
        }
        >input::placeholder{
            color: white
        }
    }
}
@media (orientation: landscape){
    .contactp{
        height: 75vh;
        >.contactc{
            >.submitCon{
                min-height: 30px;
                margin-bottom: 30px;
                color: black;
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .contactp{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 77.5vh;
        >.contactc{
            width: 50%;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
            > *{
                margin: 10px 0;
                text-shadow: 5px 5px 10px black;
            }
            > input{
                height: 20px;
                width: 150px;
                padding: 5px;
            }
            >.contactpar{
                width: 20vw;
                text-align: left;
                font-size: 20px
            }
            >.submitCon{
                height: 30px;
                width: 100px;
            }
            >h1{
                margin-top: 5vh;
                font-size: 40px;
            }
        }
    }
}