.jobsp{
    height: 85vh;
    // max-height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    >.jobsc{
        @extend .jobsp;
        overflow-y: scroll;
        img{
            height: 40vh;
            width: 90vw;
            // max-height: 227px;
            // max-width: 288px;
            // margin-bottom: 5px;
        }
        >.showDesc{
            width: 100vw;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: greenyellow;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 900;
        }
        >.picInfo{
            
            >.showDesc{
                text-align: center;
                cursor: pointer;
                color: greenyellow;
                transform: rotate(180deg);
                font-size: 20px;
                font-weight: 900;
            }
        }
        // >.picInfo{
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        //     width: 100%;
        //     height: 20vh;
        //     animation: picInfo 0.5s ease-in 1;
        //     >.showDesc{
        //         cursor: pointer;
        //         color: greenyellow;
        //     }
        // }
        // @keyframes picInfo {
        //     0%{ 
        //         font-size: 0;
        //         height: 0;
        //         width: 100%
        //     }
        //     100%{
        //         height: 10vh;

        //     }
            
        // } 
        .descriptionDiv{
            font-size: 10px;
            margin-top: 0;
            margin-bottom: 2px;
            color: white;
        }
    }
}
@media (orientation: landscape){
    .jobsp{
        height: 75vh;
        >.jobsc{
            img{
                height: 80vh;
                width: 80vw;
                // max-height: 80vh;
                // max-width: 80vw;
            }
        }
    }
}

@media screen and (min-width: 1024px){
    .jobsp{
        // max-height: 200vh;
        height: 77.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        >.jobsc{
            height: 200vh;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            overflow-y: scroll;
            margin-top: 2vh;
             img{
                height: 75vh;
                width: 60vw;
                // max-height: 50vh;
                // max-width: 50vw;
                margin: 0 20px 5px 20px;
                // margin-bottom: 5px;
            }
            >.showDesc{
                width: 100vw;
                height: 1vh;
                position: relative;
                top: -7px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: greenyellow;
                font-size: 50px;
                font-weight: 900;
            }
            .showDesc:hover{
                text-shadow: 0 0 10px greenyellow;
            }
            >.picInfo{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 12.5vh;
                animation: picInfo 0.5s ease-in 1;
                >.showDesc{
                    cursor: pointer;
                    color: greenyellow;
                    font-size: 50px;
                    font-weight: 900;
                }
                >p{
                    color: white;
                    margin: 0 0 15px 0;
                }
            }
            @keyframes picInfo {
                0%{ 
                    font-size: 0;
                    height: 5vh;
                    width: 100%
                }
                
            } 
            >.descriptionDiv{
                width: 100vw;
                text-align: center;
                position: relative;
                top: -5px;
                font-size: 16px;
            }
            
            
            
            // >.imgoverlay{
            //     cursor: pointer;
            //     position: absolute;
            //     top: 22.5vh;
            //     bottom: 0;
            //     left: 32vw;
            //     right: 0;
            //     height: 50vh;
            //     width: 50vh;
            //     background: black;
            //     opacity: 0;
            //     transition: 0.5s;
            // }
            // >.jobsimg:hover .imgoverlay{
            //     opacity: 1
            // }
        }
    }

}