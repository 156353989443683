.homep{
    height: 85vh;
    // max-height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    >.homec{
        @extend .homep;
        overflow-y: scroll;
        text-align: center;
        padding-top: 2vh;
        >.categoryLinks{
            text-decoration: none;
            // width: 100vw;
            >img{
                // max-height: 227px;
                // max-width: 288px;
                height: 40vh;
                width: 90vw;
            }
            >p,h1{
                margin: 0;
                color: white;
            }
            >h1{
                margin-bottom: 5px;
            }
            >p{
                font-style: italic;
                margin: 0 auto 15px auto;
                text-align: left;
                max-height: 25vh;
                overflow-y: auto;
                max-width: 90vw;
                >button{
                    background-color: greenyellow;
                    height: 20px;
                    width: fit-content;
                    border: none;
                    border-radius: 5px;
                    margin: 0 5px;
                    >*{
                        color: black;
                        text-decoration: none;
                    }
                }
            }
            
        }
    }
}
@media (orientation: landscape){
    .homep{
        height: 75vh;
        >.homec{
            >.categoryLinks{
                >img{
                    // max-height: 80vh;
                    // max-width: 80vw;
                    height: 100vh;
                    width: 80vw;
                }
                >h1{
                    // position: relative;
                    // z-index: 1;
                    // top: -40px;
                    text-shadow: 1px 0 5px black;
                }
                >p{
                    margin-bottom: 5vh;
                }
            }
        }
    }
}


@media screen and (min-width: 1024px){
    .homep{
        // max-height: 200vh;
        height: 77.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        >.homec{
            height: 200vh;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            overflow-y: scroll;
            // padding-top: 2vh;

            >.categoryLinks{
                // margin-bottom: 5vh;
                >img{
                    // max-height: 60vh;
                    // max-width: 60vw;
                    height: 75vh;
                    width: 60vw;
                }
                >h1{
                    position: relative;
                    z-index: 1;
                    top: -350px;
                    opacity: 0.5;
                    transition: 0.5s;
                }
                >p{
                    max-width: 50%;
                    font-size: 20px;
                    // padding: 0 25%;
                    // background-color: black;
                    // margin: 0 auto;
                }
                
                
            }
            >.categoryLinks > img:hover + h1{
                opacity: 1;
                text-shadow: 0 0 10px black;
            }
        }
    }

}